import React from "react";
import Container from "./container";

const MainSection50 = ({ sectionId, sectionColor, sectionType, sectionSize, childrenLeft, childrenRight, containerTitle, invert }) => {
    let sectionClass = 'main__section main__section--50'
    if (sectionColor && sectionType) {
        sectionClass += ` ${sectionType}-section ${sectionColor}`
    }
    if (sectionSize) {
        sectionClass += ` main__section--50--${sectionSize}`
    }
    if (invert) {
        sectionClass += ` main__section--50-invert`
    }
    return (
        <div id={sectionId} className={sectionClass}>
            <Container>
                {containerTitle &&
                    <Container section="5050" containerTitle>
                        {containerTitle}
                    </Container>
                }
                {childrenLeft &&
                    <Container section="5050" container50>
                        {childrenLeft}
                    </Container>
                }
                {childrenRight &&
                    <Container section="5050" container50>
                        {childrenRight}
                    </Container>
                }
            </Container>
        </div>
    )
}

export default MainSection50