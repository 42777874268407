import React, { forwardRef, useImperativeHandle, useState } from "react"

export const Dropdown = forwardRef(
    ({ showValue, options, onSelectOption }, ref) => {
        const [openDropdown, setOpenDropdown] = useState(false)

        const onFocusOut = () => {
            setTimeout(() => setOpenDropdown(false), 500)
        }

        const [selected, setSelected] = useState(null)
        const emitEvent = option => {
            setSelected(option.node)
            onSelectOption(option)
        }

        // Define a function to be triggered by the parent, accepting parameters
        const restartSelected = () => {
            setSelected(null)
        }

        // Expose the childFunction to the parent using useImperativeHandle
        useImperativeHandle(ref, () => ({
            restartSelected: restartSelected,
        }))

        return (
            <>
                <div className={`dropdown ${selected ? "dropdown--filled" : ""} ${openDropdown ? "dropdown--open" : ""}`}>
                    <button
                        className="dropdown__trigger input"
                        onClick={() => setOpenDropdown(true)}
                        onBlur={onFocusOut}
                    >
                        <span>{selected?.name ?? showValue}</span>
                        <i className="icon-chevron-down"></i>
                    </button>
                    <div className="dropdown__menu">
                        {options.map(option => {
                            return (
                                <div
                                    key={option.node.id}
                                    className={`dropdown__menu__item ${option.node.id === selected?.id
                                        ? "dropdown__menu__item--selected"
                                        : ""
                                        }`}
                                    onClick={() => emitEvent(option)}
                                >
                                    {option.node.name}
                                </div>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }
)
