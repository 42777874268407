import React from "react";
import Container from "./container";

export const MainSection7030 = ({sectionId, sectionColor, sectionType, children70, children30, containerTitle}) => {
    let sectionClass = 'main__section main__section--30-70'
    if (sectionColor && sectionType) {
        sectionClass += ` ${sectionType}-section ${sectionColor}`
    }
    return (
        <div id={sectionId} className={sectionClass}>
            <Container>
                {children30}
                {children70}
            </Container>
        </div>
    )
}